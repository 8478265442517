<template>
  <div style="background: #f6f6f6;color: #000;">
    <van-nav-bar title="" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #title>
        {{ source }} 账单
      </template>
    </van-nav-bar>
    <div class="tops">

    </div>
    <div class="nav-bar">
      <div class="flisx">
        <div style="margin-left: 10px;font-size: 14px;">
          账单号：{{ accountNumber }}
        </div>
        <span class="jsname ">
          <!-- status ：-1:撤销 0:待结账 1:已结账 2待审核  3已审核 -->
          <span v-if="form.status == 0" class="cOrange">待结</span>
          <span v-else-if="form.status == 1" class="cGreen">已结</span>
          <span v-else-if="form.status == 2" class="cOrange">待审核</span>
          <span v-else-if="form.status == 3" class="cGreen">已审核</span>
          <span v-else-if="form.status == -1" style="cBlue">已撤销</span>
        </span>
      </div>

      <div class="ywdiv">
        <div>
          业务员：
          <span style="color: #666;">{{ sellsMan ? sellsMan : '暂无' }}</span>
        </div>
        <div>共 <span class="tai">{{ form.sourceListVOS.length }}</span> 台车</div>
      </div>
    </div>
    <div style="padding-top: 46px;padding-bottom:120px;">

      <div class="mian" v-for="(item, idx) in form.sourceListVOS" :key="idx">
        <!-- @click="tourl(item)" -->
        <div class="zuolit">
          <van-row class="xmdiv">
            <van-col span="18" class="flx">
              <div class="carNo" @click="copy(item.carNo)">{{ item.carNo }}</div>
              <div class="carNo" style="margin-left: 25px;font-weight: normal;">{{ item.carModel }}</div>
            </van-col>
            <van-col span="6" style="font-size: 18px;text-align: right;">
              <div><span style="color: #ff1d1d;" v-if="!item.accident">￥{{ item.amount }}</span>
                <span style="color: #0dcc37;" v-else>￥{{ item.fd }}</span>
              </div>
            </van-col>
          </van-row>
          <!-- <van-row class="xmdiv">
              <van-col span="12">车型：{{ item.carModel ? item.carModel : '暂无车型' }}</van-col>
            </van-row> -->
          <van-row class="xmdiv" v-if="!item.accident">
            <van-col span="12" style="color: #999;">施工项目 </van-col>
            <van-col span="12" style="color: #999;text-align: right;">共 <span style="color: #000;">{{ item.counts }}</span> 项 </van-col>
          </van-row>
          <van-row class="xmdiv" v-else>
            <van-col span="24" style="color: #c68d11;">事故返点 </van-col>
          </van-row>
          <van-row class="xmdiv">
            <van-col span="24" style="color: #0dcc37;">{{ item.item }}<span v-if="item.accident">={{ item.fd
                }}</span></van-col>
          </van-row>
          <van-row class="xmdiv" style="color: #999;">
            <van-col span="12">接车:<span style="color: #000;">{{ item.createdDate ? item.createdDate.substring(5, 16) : '' }}</span></van-col>
            <van-col span="12" class="ris">交车:<span style="color: #000;">{{ item.completeTime ? item.completeTime.substring(5, 16) : '' }}</span></van-col>
          </van-row>
          <van-row class="xmdiv" v-if="item.accountNumber" style="color: #999;border-top: 1px dashed #dcdcdc;">
            <van-col span="12">已出账单号：</van-col>
            <van-col span="12" class="ris">{{ item.accountNumber }}</van-col>
          </van-row>
        </div>
      </div>
    </div>

    <div style="height: 150px;"></div>
    <div class="fxtext" v-if="fenxiang != 'true'" @click="tofenxiang">
      <img class="fximg" src="../../../assets/img/fenx.png" alt="">
      <div>分享</div>
    </div>
    <div class="fxtextfk" v-if="url">
      <img class="fximg" @click="toimgdan(url)" :src="url" alt="">
      <div style="color: red;">点击付款</div>
    </div>
    <div class="bot">
      <van-row class="paybox" style="border-top: 1px dashed #dcdcdc;">
        <van-col span="12" class="tl">支付方式：</van-col>
        <van-col span="12" class="tr">
          <el-select :disabled="form.status==2 || form.status==2" style="width: 130px" v-model="payType" size="mini" default-first-option placeholder="请选择">
            <el-option v-for="(item, iss) in columns" :key="iss" :label="item.typeStr" :value="item.id"></el-option>
          </el-select>
        </van-col>
      </van-row>
      <div class="yixuan">
        <div class="fkpz">
          <div style="padding-top: 10px;">
            <div>付款凭证</div>
            <div class="gtai">共 <span class="tai">{{ form.sourceListVOS.length }}</span> 台车</div>
          </div>
          <div class="imgdiv" v-for="(item, is) in form.pics" :key="is">
            <img class="pzimgs" @click="toimgdan(item.picUrl)" :src="item.picUrl" alt="">
            <img class="scimgs" @click="dltimgs(item, is)" src="../../../assets/img/guan2.png" alt="">
          </div>
          <van-uploader :after-read="onReadfkm">
            <div class="scdiv">
              <van-icon name="plus" />
            </div>
          </van-uploader>
        </div>
        <div class="hjtext" @click="tofenxiang">
          <div><span class="let">自费车辆：</span><span class="risj">￥{{ form.zftotal }}</span></div>
          <div><span class="let"> 事故车：</span><span class="risj">￥{{ form.sgtotal }}</span></div>
          <div><span class="let">合计：</span><span class="hjjin">￥{{ form.total }}</span></div>
        </div>
      </div>

      <div class="flex" v-if="form.status==2 || form.status==3">
        <div class="flex1" style="padding-left:4px;">
          <p>提交时间：{{form.bizDate }}</p>
          <p>提交人：{{form.submitter }}</p>
        </div>
        <van-button v-if="form.status==2" @click="cexiao" class="cRed cexiao">撤销</van-button>
        <van-button type="danger" v-if="form.status==2" disabled style="width:100px;">等待审核</van-button>
        <van-button type="primary" v-if="form.status==3" disabled style="width:120px;">审核通过</van-button>
      </div>
      <div v-else>
        <div class="flex" v-if="fenxiang == 'true'">
          <van-button v-if="form.settleDate == null" class="flex1" type="primary" @click="tolist">查看账单列表</van-button>
          <van-button class="ssdh flex1" v-else>收款时间:{{ form.settleDate ? form.settleDate.substring(0,16):''}}</van-button>
          <div v-if="!ygshouw" class="flex1">
            <van-button type="danger" v-if="form.status == 0" @click="queren">确认收款</van-button>
            <van-button type="danger" v-if="form.status == 1" disabled>{{ form.name }} 已付款</van-button>
          </div>
          <div v-else class="flex1">
            <van-button type="danger" v-if="form.status == 0" @click="tofukuan">去付款</van-button>
            <van-button type="danger" v-if="form.status == 1" disabled>{{ form.name }} 已付款</van-button>
          </div>
        </div>
        <div class="flex" v-else>
          <van-button type="danger" v-if="form.settleDate == null && form.status != -1" @click="cexiao">撤销账单</van-button>
          <van-button class="ssdh" v-if="form.settleDate && form.status != -1">收款时间:{{ form.settleDate ? form.settleDate.substring(0, 16) : '' }}</van-button>
          <van-button type="primary" v-if="form.status == 0" @click="queren">确认收款</van-button>
          <van-button type="primary" v-if="form.status == 1" disabled>{{ form.name }} 已确认收款</van-button>
          <van-button type="danger" v-if="form.status == -1" disabled>{{ form.name }} 已撤销</van-button>
        </div>
      </div>
    </div>
    <div v-if="path" class="jtimg" @click="setimg">
      <img id="img" :src="path" style="width: 100%" />
      <div class="van-uploader__preview-delete" @click.stop="path = null">
        <van-icon class=" van-uploader__preview-delete-icon" name="cross" />
      </div>
    </div>
  </div>
</template>

<script>
import { accountdetails, accountpic, uploadPicture, sourceaccount, deleteaccount, putaccount, accountconfirm, getGarageInfo, getsourceamount, checkAuth, getpay } from "@/api/check";
// import userModel from "@/api/user";
import moment from "moment";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  data() {
    return {
      form: { sourceListVOS: [] },
      carlist: [],
      num: 0,
      price: 0,
      gid: this.$route.query.gid,
      source: this.$route.query.source,
      accountNumber: this.$route.query.accountNumber,
      names: this.$route.query.names,
      fenxiang: this.$route.query.fenxiang,
      acvred: null,
      phone: "",
      amount: "",
      key: "",
      remark: "",
      ygshouw: false,
      timeshow: false,
      checked: false,
      checkedqx: false,
      path: '',
      url: null,
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(2030, 10, 1),
      status: null, //工单计收还是预付款
      yfkisok: false,
      acv: 0,
      show: false,
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")) || {},
      user: JSON.parse(localStorage.getItem("userInfo")),
      page: 0,
      size: 20,
      loading: false,
      total: 0,
      finished: false,
      isiOS: false,
      currentDate: new Date(),
      time: moment().format("YYYY-MM"),
      sellsMan: "",
      payType: null,
      columns: []
    };
  },
  watch: {},
  methods: {
    getpayFun() {
      getpay({ gid: this.gid }).then(res => {
        if (res.code == 200) {
          this.columns = res.data
        }
      })
    },
    dltimgs(item, idx) {
      deleteaccount(item.id).then((e) => {
        if (e.code == 200) {
          this.form.pics.splice(idx, 1);
          this.$toast("删除成功");
        }
      });
    },
    copy(item) {
      const input = document.createElement("input");
      document.body.appendChild(input);
      var http = item;
      input.setAttribute("value", http);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$toast("已复制到粘贴板！");
      }
      document.body.removeChild(input);
    },
    //确认收款
    queren() {
      if (this.form.pics.length == 0) {
        this.$toast("请先上传付款凭证!");
        return
      }
      if (!this.payType) {
        this.$toast("请选择支付方式!");
        return
      }
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认收款？",
        })
        .then(() => {
          let params = {
            accountNumber: this.accountNumber,
            amount: this.form.total,
            payType: this.payType
          }
          accountconfirm(params).then((e) => {
            if (e.code == 200) {
              this.$toast("确认成功");
              setTimeout(() => {
                this.getlist()
              }, 800);
            }
          });
        })
        .catch(() => {
          // on cancel
        });

    },
    cexiao() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认撤销？",
        })
        .then(() => {
          putaccount({ accountNumber: this.accountNumber }).then((e) => {

            if (e.code == 200) {
              this.$toast("撤销成功");
              setTimeout(() => {
                this.$router.go(-1);
              }, 800);
            }
          });
        })
        .catch(() => {
          // on cancel
        });

    },
    async onReadfkm(file) {

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "上传中...",
      });
      var _this = this;


      let files = this.dataURLtoFile(file.content, file.file.name);
      let formdata1 = new FormData();
      formdata1.append("file", files);

      uploadPicture(formdata1).then((e) => {
        loading.clear();
        if (e.code == 200) {
          _this.setaddpic(e.data);
        }
      });
    },
    async onReadss(file) {

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "上传中...",
      });
      var _this = this;

      let formdata1 = new FormData();
      formdata1.append('file', file);

      uploadPicture(formdata1).then((e) => {
        loading.clear();
        if (e.code == 200) {
          this.path = null
          _this.setaddpic(e.data);
        }
      });
    },
    //上传付款码
    setaddpic(img) {
      var data = {
        accountNumber: this.accountNumber,
        picUrl: img,
      };
      accountpic(data).then((e) => {
        if (e.code == 200) {
          this.$toast.success("上传成功");
          this.getlist()
        }
      });
    },
    dataURLtoFile(dataurl, filename) {
      // 将base64转换为file文件
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
    tolist() {
      var data = [];
      var list = this.form.sourceListVOS
      for (var i in list) {
        data.push(list[i].oid);
      }
      var ids = data.join();
      location.href =
        "http://customer.car-posthouse.cn/reconciliation.html?gid=" + this.gid + "&ids=" + ids + "&zftotal=" + this.form.zftotal + "&sgtotal=" + this.form.sgtotal + "&total=" + this.form.total + "&accountNumber=" + this.accountNumber + "&token=" + localStorage.getItem('Authorization');
      console.log(ids);
      // this.$router.push({
      //   path: "bill_list",
      //   query: {
      //     gid: this.gid,
      //     source: this.source,
      //     zftotal: this.form.zftotal,
      //     sgtotal: this.form.sgtotal,
      //     total: this.form.total,
      //     accountNumber: this.accountNumber
      //   },
      // });
    },
    tofukuan() {
      this.$router.push({
        path: "bill_pay",
        query: {
          accountNumber: this.accountNumber,
          gid: this.gid,
          amount: this.form.total,
          garageName: this.form.garageName
        },
      });
    },
    toyulan() {
      // var data = [];
      // var ids = "";
      // for (var i in this.list) {
      //   if (this.list[i].checked) {
      //     data.push(this.list[i].oid);
      //   }
      // }
      // if (data.length == 0) {
      //   this.$toast("请先选择再预览");
      //   return;
      // }
      // var ids = data.join();
      // location.href =
      //   "http://customer.car-posthouse.cn/reconciliation.html?gid=" +
      //   this.gid +
      //   "&ids=" +
      //   ids;
      // console.log(ids);
      var source = [];
      for (var i in this.list) {
        if (this.list[i].checked) {
          var ris = {
            gid: this.gid,
            source: this.source,
            oid: this.list[i].oid,
            status: 0
          }
          source.push(ris);
        }
      }
      if (source.length == 0) {
        this.$toast("请先选择再预览");
        return;
      }
      sourceaccount(source).then((res) => {
        if (res.code == 200) {
          this.$toast.success("生成账单成功");
        }
      });
    },
    tourss(item) {
      location.href =
        "http://customer.car-posthouse.cn/reconciliation.html?gid=" +
        this.gid +
        "&ids=" +
        item.oid;
    },

    tourls() {
      this.$router.push({
        path: "/offer",
        query: {
          gid: this.gid,
        },
      });
    },
    initData() {
      this.getlist()
    },
    getlist() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      var data = {
        gid: this.gid,
        accountNumber: this.accountNumber,
      };
      accountdetails(data).then((e) => {
        loading.clear();
        // console.log(e);
        this.getewm()
        if (e.code == 200) {
          this.form = e.data
          this.payType = e.data.payType
          this.form.sourceListVOS = e.data.sourceListVOS ? e.data.sourceListVOS : []
        }
      });
    },
    onLoads() {
      this.page++;
      this.getlist();
    },
    toimgdan(img) {
      this.$router.push({
        name: "showimg",
        query: {
          img: img,
          idx: 0,
        },
      });
    },

    back() {
      this.$router.go(-1);
    },
    tofenxiang() {
      var _this = this
      var sy = moment().subtract(1, 'months').startOf('month').format('MM')
      var dy = moment().format("MM");

      if (this.form.title) {
        var title = this.form.title
      } else {
        var title = sy + '-' + dy + ' 月份账单 部分车辆'
      }
      // return
      var map = {
        'title': title,
        'url': 'https://shopping.car-posthouse.cn/bill_details?gid=' + _this.gid + '&source=' + this.source + "&accountNumber=" + this.accountNumber + '&fenxiang=' + 'true',
        'description': '【' + this.source + '】' + '尊敬的客户，您的账单已生成，请点击查看',
        'image': 'image_6'
      }
      let arr = JSON.stringify(map)
      if (_this.isiOS) {
        _this.setupWebViewJavascriptBridge((bridge) => {
          var navBack = bridge.callHandler('ShareToWechat', arr, function responseCallback(responseData) {
            // alert('走了这里' + responseData)
            return responseData;
          });

        })
      } else {

        window.androidjs.onCallbackIntent("ShareToWechat", arr, false);
      }
    },
    setupWebViewJavascriptBridge(callback) {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      let WVJBIframe = document.createElement("iframe");
      WVJBIframe.style.display = "none";
      WVJBIframe.src = "https://__bridge_loaded__";
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    },
    //
    appSource() {
      if(this.fenxiang == 'true'){
        this.initData()
        return ;
      }
      var _this = this;
      const u = navigator.userAgent,
        app = navigator.appVersion;
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      try {
        if (this.isiOS) {
        this.isok = false;
        this.setupWebViewJavascriptBridge((bridge) => {
          var token = bridge.callHandler(
            "getToken",
            null,
            function responseCallback(responseData) {
              localStorage.setItem("Authorization", responseData);
              _this.token = responseData;
              _this.initData();
              return responseData;
            }
          );
        });
        console.log("ios");
      } else {
        this.isok = true;
        _this.token = window.androidjs.getLocalData("getToken");
        localStorage.setItem("Authorization", _this.token);
        _this.initData();
        console.log("andriod");
      }
      } catch (error) {
        _this.token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNDk3IiwiZXhwIjoxNzQxNDU2NDk3fQ.DwNuMEn8ETVYDedtKzO5BubPdFhPiM9_0PeRZEDJHHKyKxc_u3q2L2u86m3gA3sY4igNVsz2kHK-gmOSfqHryg";
        localStorage.setItem("Authorization", _this.token);
        _this.initData();
        console.log("token====",_this.token);
      }
    },
    setimg() {
      var file = this.dataURLtoFile(this.path, '.jpg')
      this.onReadss(file)
    },
    showRefresh() {

      if (!this.isiOS && this.fenxiang != "true") {
        var path = window.androidjs.getLocalData('getLatestScreenshot');
        if (path) {
          this.path = "data:image/jpeg;base64," + path;
        }
      }
    },
    getewm() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      getsourceamount({ gid: this.gid }).then((e) => {
        loading.clear();
        if (e.code == 200) {
          console.log(e)
          this.url = e.data
        }
      });
    },
  },
  tobendian() {
    const loading = this.$toast.loading({
      mask: true,
      duration: 0,
      message: "加载中...",
    });
    checkAuth({
      gid: this.gid,
    })
      .then((e) => {
        loading.clear();
        // console.log(e.data)
        if (e.data) {
          this.ygshouw = true;
        }
      })
      .catch();
  },
  created() {
    // this.getlist();
    this.accountNumber = this.$route.query.accountNumber;
    this.gid = this.$route.query.gid;
    this.source = this.$route.query.source;
    this.names = this.$route.query.names;
    this.fenxiang = this.$route.query.fenxiang;
    // localStorage.setItem("Authorization", 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5MTA1MSIsImV4cCI6MTY4MDIzMzk5M30.u2mS78EduF3AQCt7WVaVEDZ2ZJVpDLsHS8-IGOJCHZJlxjdxteEevcANJuO355SVpz0yS1QXzhJKcshORgorow');
    var url =
      this.$route.path +
      "?gid=" +
      this.gid +
      "&accountNumber=" +
      this.accountNumber +
      "&source=" +
      this.source + "&names=" +
      this.names +
      "&fenxiang=" +
      this.fenxiang;
    localStorage.setItem("beforeUrl", url);

    sessionStorage.setItem("Agid", this.gid);
    sessionStorage.setItem("gid", this.gid);
    console.log("gid===",this.gid)
    localStorage.setItem("gid", this.gid);

    this.getpayFun() //获取支付方式
    getGarageInfo({
      gid: this.gid,
    })
      .then((e) => {
        // loading.clear();
        this.shangjia = e.data;
        sessionStorage.setItem("appid", e.data.idd);
        sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
        document.title = e.data.name;
        if (localStorage.getItem('Authorization')) {
          this.getlist()
          this.tobendian()
          // 
        } else {
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
          // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
          return;
        }

      })
    // .catch(() => {loading.clear()});
    //判断是否在微信环境
    this.appSource();
  },
  mounted() {
    document.addEventListener('visibilitychange', this.showRefresh());

    if (!this.isiOS && this.fenxiang != "true") {
      var path = window.androidjs.getLocalData('getLatestScreenshot');
      if (path) {
        this.path = "data:image/jpeg;base64," + path;
      }

    }
  },
  computed: {},
};
</script>
<style>
body {
  background: #f6f6f6;
  font-size: 14px !important;
}
.cRed {
  color: red;
}
.cOrange {
  color: #ff7f27;
}
.cGreen {
  color: #007832;
}
.cBlue{
  color: #2824ff;
}
</style>
<style lang="less" scoped>
.nav-bar {
  position: sticky;
  top: 46px;
  left: 0;
  width: 100%;
  background: #fff;

  line-height: 40px;
  font-size: 16px;
  z-index: 99;
}

.ssdh {
  font-size: 12px !important;
  &.van-button{
    padding: 0;
  }
}

.tai {
  font-size: 16px;
  color: red;
}

.flisx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ywdiv {
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 12px;
  justify-content: space-between;
  font-size: 14px;
}

.carNo {
  color: #fff;
  font-family: JDZhengHT-Regular;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  letter-spacing: 0;
  text-align: center;
}

.flx {
  display: flex;
  align-items: center;
}

.wj {
  background: rgb(255, 8, 8);
}

.yj {
  background: #007832;
}

.jsname {
  color: rgb(255, 8, 8);
  padding: 1px 6px;
  border-radius: 2px;
  font-size: 14px;
  height: 26px;
  line-height: 26px;
}

.gz {
  background: rgb(171, 94, 0);
  color: #fff;
  padding: 1px 6px;
  border-radius: 2px;
  font-size: 14px;
}

.time {
  padding: 6px 12px;
  font-size: 16px;
  font-weight: bold;

}

.xzs {
  border: 1px solid #007832 !important;
  margin-right: 5px;
}

.jtimg {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-bottom: 150px;
  width: 60px;
  background: #fff;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0px 6px 20px 0px rgba(227, 233, 237, 1);
  margin-right: 20px;
}

.jtimg img {
  display: block;
  border-radius: 4px;
}

.gtai {
  font-size: 12px;
  margin-top: 15px;
}

.mian {
  margin: 0 12px;
  border-top: 1px solid #f5f5f5;
  border-radius: 6px;
  margin-top: 10px;
  padding: 10px 0;
  background: #fff;
  display: flex;
  align-items: center;

  .fximg {
    width: 24px;
    margin-left: 22px;
  }

  .sc {
    width: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .zuolit {
    flex: 1;
    border-right: 1px dashed #dcdcdc;
  }

  .xmdiv {
    line-height: 30px;

    font-size: 14px;
    padding: 0 12px;
  }

  .ris {
    text-align: right;
  }

  .tjdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 40px;
    background: #fff;
    // line-height: 40px;
    padding: 10px 0;
    border-top: 1px solid #f4f4f4;
  }
}

.fxtext {
  font-size: 12px;
  color: #666;
  position: fixed;
  bottom: 180px;
  left: 12px;
  text-align: center;
  line-height: 12px;
  font-size: 12px;
  z-index: 99;

  img {
    padding: 2px;
    background: #fff;
    border-radius: 50%;
  }
}

.fxtextfk {
  font-size: 12px;
  color: #666;
  position: fixed;
  bottom: 160px;
  right: 12px;
  text-align: center;
  line-height: 12px;
  font-size: 12px;

  img {
    padding: 2px;
    background: #fff;
    border-radius: 50%;
    width: 42px;
  }
}

.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #fff;
  z-index: 9;

  .yixuan {
    // width: 70%;
    display: flex;
    justify-content: space-between;
    line-height: 25px;
    background: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 14px;
    height: 80px;
    line-height: 16px;

    .fkpz {
      display: flex;
      margin-top: 5px;
    }

    .scdiv {
      width: 45px;
      height: 70px;
      border: 1px solid #dcdcdc;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

    }

    .hjtext {
      font-size: 14px;
      line-height: 24px;

      .risj {
        font-size: 12px;
        display: inline-block;
        min-width: 60px;
        text-align: left;
      }

      .hjjin {
        color: red;
        font-weight: bold;
        display: inline-block;
        min-width: 60px;
        text-align: left;
      }
    }



    .let {
      display: inline-block;
      width: 85px;
      text-align: right;
      font-size: 13px;
      font-weight: bold;
      color: #666;

    }

    .fximg {
      width: 50px;
      display: block;
      margin: auto;
      margin-top: 5px;
    }
  }

  .flex {
    display: flex;
  }
  .flex1{
    flex: 1;
  }

  .imgdiv {
    width: 45px;
    height: 70px;
    margin-left: 10px;
    position: relative;

    .pzimgs {
      width: 45px;
      height: 70px;
    }

    .scimgs {
      width: 24px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
    }
  }

  .van-button {
    width: 100%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
    border-radius: 0;
  }
}

.paybox {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}
.cexiao{
  width: 40px!important;
  padding: 0;
  border: 0;
}
</style>